@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Ubuntu, 'Helvetica Neue', sans-serif;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

#overlay-base {
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(2, 2, 61, 0) 41%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(2, 2, 61, 0) 41%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(2, 2, 61, 0) 41%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#000000",GradientType=1);
}
